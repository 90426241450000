import React, { useEffect, useState } from "react";
import { serverUrl } from "../../Utils/server";
import axios from "axios";
// import { currencySymbol } from "../../Utils/currency";
import ReactLoading from "react-loading";
import FontAwesome from "react-fontawesome";
import { useHistory } from "react-router";

function Dashboard() {
  const history = useHistory();
  const [usersCount, setUsersCount] = useState(0);
  const [sessionCount, setSessionCount] = useState(0);
  const [rewardCount, setRewardCount] = useState(0);
  const [rewardProfileCount, setRewardProfileCount] = useState(0);
  const [eventsCount, setEventsCount] = useState(0);
  const [reportsCount, setReportsCount] = useState(0);
  // const [totalEarnings, setTotalEarnings] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDashboardData = () => {
    setLoading(true);

    const config = {
      method: "get",
      url: `${serverUrl}/admin-dashboard`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then((response) => {
        setUsersCount(response.data.totalUsers);
        setSessionCount(response.data.totalSession);
        setRewardCount(response.data.totalReward);
        setRewardProfileCount(response.data.totalRewardProfile);
        setEventsCount(response.data.totalEvents);
        setReportsCount(response.data.totalReports);
        // setTotalEarnings(response.data.totalEarnings);
        setLoading(false);
      })
      .catch((err) => {
        localStorage.clear();
        history.push("/login");
        setLoading(false);
      });
  };

  return (
    <div className="content-body">
      <div className="content-title">
        <p className="dashboard">Dashboard</p>
      </div>
      <div>
        {loading ? (
          <ReactLoading
            type={"spinningBubbles"}
            color={"var(--primaryColor)"}
            width={"2rem"}
            height={"2rem"}
          />
        ) : (
          <>
            <div
              className="row"
              style={{ flexWrap: "wrap", marginTop: "2rem" }}
            >
              {/* <div className="card yellow-card">
                <div className="column">
                  <span>
                    {currencySymbol}
                    {totalEarnings}
                  </span>
                  Total Earnings
                </div>
                <FontAwesome
                  name="money"
                  style={{ fontSize: "4rem", marginRight: "1rem" }}
                />
              </div> */}
              {/* <div
                className="card green-card"
                onClick={() => history.push("/orders")}
              >
                <div className="column">
                  <span>{ordersCount}</span>
                  Orders
                </div>
                <FontAwesome
                  name="shopping-bag"
                  style={{ fontSize: "4rem", marginRight: "1rem" }}
                />
              </div> */}
              <div
                className="card pink-card"
                onClick={() => history.push("/users")}
              >
                <div className="column">
                  <span>{usersCount}</span>
                  Users
                </div>
                <FontAwesome
                  name="user"
                  style={{ fontSize: "4rem", marginRight: "1rem" }}
                />
              </div>
              <div
                className="card blue-card"
                onClick={() => history.push("/events")}
              >
                <div className="column">
                  <span>{eventsCount}</span>
                  Events
                </div>
                <FontAwesome
                  name="cube"
                  style={{ fontSize: "4rem", marginRight: "1rem" }}
                />
              </div>
              <div
                className="card red-card"
                onClick={() => history.push("/reward")}
              >
                <div className="column">
                  <span>{rewardCount}</span>
                  Rewards
                </div>
                <FontAwesome
                  name="Example of gift fa-gift"
                  style={{ fontSize: "5rem", marginRight: "1rem" }}
                />
              </div>
              <div
                className="card orange-card"
                onClick={() => history.push("/sessions")}
              >
                <div className="column">
                  <span>{sessionCount}</span>
                  Sessions
                </div>
                <FontAwesome
                  name="list"
                  style={{ fontSize: "4rem", marginRight: "1rem" }}
                />
              </div>
              <div
                className="card green-card"
                onClick={() => history.push("/rewardProfile")}
              >
                <div className="column">
                  <span>{rewardProfileCount}</span>
                  RewardProfile
                </div>
                <FontAwesome
                  name="cubes"
                  style={{ fontSize: "4rem", marginRight: "1rem" }}
                />
              </div>
              <div
               className="card yellow-card"
                onClick={() => history.push("/Reports")}
              >
                <div className="column">
                <span>{reportsCount}</span>
                Reports
              </div>
              <FontAwesome
                name="Example of database fa-database"
                style={{ fontSize: "4rem", marginRight: "1rem" }}
              />
              </div>
              
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
