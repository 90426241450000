import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { serverUrl } from "../../Utils/server";
import axios from "axios";
import FontAwesome from "react-fontawesome";
import { toast, ToastContainer, darkToast } from "../../Components/Toast";
import Sessions from "./Sessions";
import { useParams } from "react-router";
// import { useParams } from "react-router";
import Modal from "react-modal/lib/components/Modal";
import ViewReports from "./QR_Reports";
import { useHistory } from "react-router";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
//import { QRCode } from "react-qrcode-logo";
const perPage = 5;

function Reports() {
  const history = useHistory();
  const { sessionId } = useParams([]);
  // const { RewardId } = useParams([]);
  const { QrId } = useParams([]);
  let RewardId = window.location.pathname
    .replace("/sessions/Reports/", "")
    .trim()
    .split("/")[1];
  // console.log(RewardId);
  // console.log(sessionId);
  // console.log(RewardId);
  // console.log(QrId);
  const [reports, setReports] = useState("");
  // console.log(reports);
  const [searchReports, setSearchReports] = useState("");
  const [slectevalue, setslectevalue] = useState("");
  const [searchViewReports, setSearchViewReports] = useState("");
  const [loading, setLoading] = useState(false);
  // const [GiftStatus, setGiftStatus] = useState();
  // const [GiftStatusId, setGiftStatusId] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [deleteViewReports, setDeleteViewReports] = useState("");

  //   useEffect(() => {
  //     // console.log(props);
  //     // getQrImage();
  //     let sessions = localStorage.getItem("sessions") ?? [];
  //     console.log(JSON.parse(sessions));
  //     sessions = JSON.parse(sessions);
  //     let _id = window.location.pathname.replace("/sessions/ViewReports/", "").trim();
  //     sessions.forEach((item) => {
  //       if (item._id == _id) {
  //         setReports(item.QrImage);
  //       }
  //     });
  //   }, []);

  useEffect(() => {
    getReports();
    getRewardReports();
  }, []);

  //GIFT STATUS API
  const getRewardCollect = (reportID, status) => {
    console.log(reportID, status);
    setLoading(true);
    const config = {
      method: "post",
      url: `${serverUrl}/reports/RewardCollect/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        ReportId: reportID,
        status: status,
      },
    };
    axios(config).then((response) => {
      console.log(response);

      setLoading(false);
    });
  };

  const getRewardReports = () => {
    setLoading(true);

    const config = {
      method: "post",
      url: `${serverUrl}/reports/RewardsScannedData/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        QRCode: sessionId,
        RewardId: RewardId,
      },
    };

    axios(config).then((response) => {
      //   console.log(response);
      setReports(response.data);
      //console.log(response.data);
      setLoading(false);
    });
  };

  const getReports = () => {
    setLoading(true);

    const config = {
      method: "get",
      url: `${serverUrl}/reports/getReportsForWeb/${sessionId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };

    axios(config).then((response) => {
      //  console.log(response);
      setReports(response.data);
      //  console.log(response.data);
      setLoading(false);
    });
  };

  const getReportsByType = () => {
    // if (type === "all") return ;
    setLoading(true);

    const config = {
      method: "post",
      url: `${serverUrl}/reports/SearchReportsBySessionOrQRcode`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        [slectevalue]: searchReports,
      },
    };

    axios(config)
      .then((response) => {
        setReports(response.data);
        // console.log(response.data);
        setLoading(false);
        if (response.data.length === 0) {
          //  <p>no data</p>
          toast("Enter a valid session or Qr code", darkToast);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const removeReports = () => {
    setLoading(true);
    const config = {
      method: "post",
      url: `${serverUrl}/reports/remove`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        reportsId: deleteViewReports._id,
      },
    };

    axios(config)
      .then((response) => {
        toast("Reports deleted successfully!", darkToast);
      })
      .catch((err) => {
        toast("Something went wrong", darkToast);
      });

    setDeleteViewReports("");
    getReports();
    setLoading(false);
  };

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  const offset = currentPage * perPage;
  const currentPageData =
    reports &&
    reports.slice(offset, offset + perPage).map((report, index) => {
      return !report.username
        .toLowerCase()
        .includes(searchViewReports.toLowerCase()) ? (
        ""
      ) : (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{report.username}</td>

          <td>{report.usermobileNo}</td>
        </tr>
      );
    });

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function CustomToolbarGrid() {
    const columns = [
      { field: "sno", headerName: "S NO", width: 110 },
      {
        headerName: "User Name",
        field: "username",
        width: 200,
      },
      {
        headerName: "mobileNo",
        field: "usermobileNo",
        width: 200,
      },
      {
        headerName: "RewardName",
        field: "RewardName",
        width: 200,
      },
      {
        headerName: "created_at",
        field: "created_at",
        width: 200,
        renderCell: (params) => {
          const dt = params.value.split("/");
          const [month, day, year] = dt;
          dt[0] = day;
          dt[1] = month;
          dt[2] = year;
          return (
            <>
              <p>{dt.join("-")}</p>
            </>
          );
        },
      },
      {
        headerName: "QRCode",
        field: "QRCode",
        width: 150,
      },
      {
        headerName: "Gift Status",
        field: "Status",
        width: 150,
        renderCell: (params) => {
          return (
            <>
              <p>{params.row.GiftStatus ? "Collected" : "Won"}</p>
            </>
          );
        },
      },
      {
        headerName: "Action",
        field: "Action",
        width: 90,

        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation();
            console.log("dhsjkb");

            params.row.GiftStatus = true;
            getRewardCollect(params.id, true);
          };
          return (
            <>
              {/* {params.row.GiftStatus ? "Collected" : "Won"} */}
              <button onClick={onClick} disabled={params.row.GiftStatus}>
                Update
              </button>
            </>
          );
        },
      },
      // {
      //   headerName: "Status of Gift",
      //   field: "Status",
      //   width: 200,
      //   renderCell: (params) => {
      //     return (
      //       <>
      //         <button
      //         // onChange={(e) => updateStatus(e.target.checked, params.id)}
      //         // checked={params.value}
      //         >
      //           {params.Status}
      //         </button>
      //       </>
      //     );
      //   },
      // },
      // {
      //   headerName: "Main Report",
      //   field: "MainReport",
      //   width: 200,
      //   renderCell: (params) => {
      //     const onClick = (e) => {
      //       e.stopPropagation(); // don't select this row after clicking
      //       history.push("/Reports");

      //       console.log(params.id);
      //     };
      //     return (
      //       <>
      //         <button onClick={onClick}>MainReport</button>
      //       </>
      //     );
      //   },
      // },
    ];

    let rows = reports;
    console.log(rows);
    rows.forEach((element, i) => {
      rows[i]["id"] = rows[i]["_id"];
      rows[i]["sno"] = i + 1;
      rows[i]["created_at"] = new Date(element.created_at).toLocaleDateString();
    });

    //  console.log(data);

    return (
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          // {...data}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  return (
    <>
      <Modal
        isOpen={deleteViewReports !== ""}
        style={{
          content: {
            borderRadius: "1rem",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
          overlay: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        }}
      >
        <div className="modalContent">
          <p className="modalTitle">Delete Reports?</p>
          <p className="modalBody">
            Are you sure, want to delete{" "}
            <b>{`${deleteViewReports.username}`}</b> ?
          </p>
          {loading ? (
            <>
              <br />
              <ReactLoading
                type={"spinningBubbles"}
                color={"var(--primaryColor)"}
                width={"2rem"}
                height={"2rem"}
              />
            </>
          ) : (
            <div className="modalButtonsRow">
              <button
                className="cancelbutton"
                onClick={() => setDeleteViewReports("")}
              >
                Cancel
              </button>
              <button
                className="deletebutton"
                onClick={() => {
                  setLoading(true);
                  removeReports();
                  // toast("Can't delete Reports in Demo", darkToast);
                }}
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </Modal>

      <div className="content-body">
        <div className="content-title">
          <p>Reports</p>
        </div>
        <div className="filter-select">
          <p>Reports Type:</p>
          <select
            id="change"
            onChange={() => {
              const qrnovalue = document.getElementById("change").value;
              setslectevalue(qrnovalue);
            }}
          >
            <option value="" disabled selected hidden>
              Select
            </option>
            <option value="sessionName">session Name</option>
            <option value="QRCode">QR No</option>
            <option value="username">Username</option>
            <option value="usermobileNo">Mobile Number</option>
          </select>
          <input
            type="text"
            placeholder="Search Session name"
            onChange={(e) => {
              setSearchReports(e.target.value);
            }}
          />
          <button
            className="findbutton"
            onClick={() => {
              setLoading(true);
              // const qrnovalue = document.getElementById("qrno").value;
              // setslectevalue(qrnovalue);

              //   console.log(reports);
              getReportsByType();
            }}
          >
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
        </div>
        <div
          style={{
            height: loading ? "70vh" : "",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <ReactLoading
              type={"spinningBubbles"}
              color={"var(--primaryColor)"}
              width={"4rem"}
              height={"4rem"}
            />
          ) : reports.length === 0 ? (
            <p>No reports</p>
          ) : (
            <CustomToolbarGrid />
          )}
        </div>

        <ToastContainer />
      </div>
    </>
  );
}

export default Reports;
