import React, { useState } from "react";
import { darkToast, toast, ToastContainer } from "../../Components/Toast";
import ReactLoading from "react-loading";
import { useHistory } from "react-router";
import axios from "axios";
import { serverUrl } from "../../Utils/server";

export const AddReward = () => {
  const history = useHistory();
  const [rewardName, setRewardName] = useState("");
  // const [imageUrl, setImageUrl] = useState("");

  const [loading, setLoading] = useState(false);
  //const [uploadingImage, setUploadingImage] = useState(false);

  // const uploadImage = (image) => {
  //   setUploadingImage(true);

  // const fileName = `${
  //   image.name + "_" + eventsName + "_" + Date.now().toString()
  // }`;

  //   const upload = storage.ref(`images/${fileName}`).put(image);
  //   upload.on(
  //     "state_changed",
  //     (snapshot) => () => {},
  //     (error) => {},
  //     () => {
  //       storage
  //         .ref("images")
  //         .child(`${fileName}`)
  //         .getDownloadURL()
  //         .then((url) => {
  //           setImageUrl(url);
  //           setUploadingImage(false);
  //         });
  //     }
  //   );
  // };
  // useEffect(() => {
  //   getReward();
  // }, []);
  // const getReward = () => {
  //   setLoading(true);

  // };

  let existingRewards = [];
  let filteredRewards = [];
  const getRewards = () => {
    const config = {
      method: "get",
      url: `${serverUrl}/rewards/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };
    axios(config).then((response) => {
      existingRewards = response.data;
      // console.log(existingRewards);
      filteredRewards = existingRewards.map(({ RewardType }) =>
        RewardType.toLowerCase()
      );
      // console.log(filteredRewards);
      // getDataRewards(filteredRewards);
      setLoading(false);
    });
  };
  getRewards();

  //****Add Reward Function****//
  const addReward = () => {
    if (rewardName === "") {
      return toast("Reward name is required", darkToast);
    } else if (filteredRewards.includes(rewardName.toLowerCase())) {
      return toast("The reward already exists", darkToast);
    }

    // if (imageUrl === "") return toast("Upload Image", darkToast);

    setLoading(true);
    const config = {
      method: "post",
      url: `${serverUrl}/rewards/add`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        RewardType: rewardName,
        // image: imageUrl,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          toast("Reward added!", darkToast);

          setTimeout(() => {
            history.push("/reward");
            window.location.reload();
          }, 1000);
          setLoading(false);
        }
      })
      .catch(() => {
        toast("Something went wrong!", darkToast);
        setLoading(false);
      });
  };

  return (
    <div className="content-body">
      <div className="content-title">
        <p>Add New Reward</p>
      </div>
      <div className="form">
        <p>Reward Name</p>
        <input
          type="text"
          maxLength={25}
          placeholder="Reward Name"
          onChange={(e) => {
            setRewardName(e.target.value.trim());
          }}
        />
        {/* <p>Events Image</p>
        {uploadingImage ? (
          <div style={{ margin: "1rem" }}>
            <ReactLoading
              type={"spinningBubbles"}
              color={"var(--primaryColor)"}
              width={"2rem"}
              height={"2rem"}
            />
          </div>
        ) : imageUrl !== "" ? (
          <img
            style={{
              width: "20%",
              margin: "1rem",
              objectFit: "contain",
            }}
            src={imageUrl}
            alt=""
          />
        ) : null}
        <input
          type="file"
          onChange={(e) => {



            uploadImage(e.target.files[0]);
          }}
        /> */}
        <button
          onClick={() => {
            // getRewards();
            addReward();
            //  toast("Can't add Events in Demo", darkToast);
          }}
        >
          {loading ? (
            <ReactLoading
              type={"spinningBubbles"}
              color={"white"}
              width={"1.2rem"}
              height={"1.2rem"}
            />
          ) : (
            "Add Reward"
          )}
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};
