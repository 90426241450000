import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Route, useHistory } from "react-router";
import axios from "axios";
import { serverUrl } from "../../Utils/server";
import { toast, ToastContainer, darkToast } from "../../Components/Toast";
import { fontSize } from "@mui/system";
import "./Qrpage.css";
import Modal from "react-modal";
import ReactLoading from "react-loading";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import { saveAs } from "file-saver";

// import QR_Reports from "./QR_Reports";

const Qrpage = (props) => {
  const history = useHistory();
  const [sessionId, setSessionId] = useState(null);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const [qr, setQr] = useState([]);
  const [reportSession, setreportSession] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [active, setActive] = useState(false);
  const [showMore, setshowMore] = useState(false);
  const [selectedReward, setselectedReward] = useState({});
  const [totalRewards, setTotalRewards] = useState([]);
  const [QRCode, setQRCode] = useState([]);

  useEffect(() => {
    // console.log(props);
    // getQrImage();
    // getActive();
    getSessions();
    let sessions = localStorage.getItem("sessions") ?? [];
    // console.log(JSON.parse(sessions));
    sessions = JSON.parse(sessions);
    let _id = window.location.pathname.replace("/sessions/Qrpage/", "").trim();
    sessions.forEach((item) => {
      if (item._id == _id) {
        setSessionId(_id);
        setQRCode(item.QrImage.map((obj) => obj.QRCode));
        setQr(item.QrImage);
        // console.log(item.QrImage);
        setTotalRewards(
          item.rewardprofile[0].Rewards.map((obj) => +obj.Quantity)
        );
        setQr(item.QrImage);
      }
    });
  }, []);
  // console.log(totalRewards);
  const total = totalRewards.reduce(function (a, b) {
    return a + b;
  }, 0);
  // console.log(total);

  const updateStatusBy_id = (_id, status) => {
    let index = qr.findIndex((item) => item._id == _id);
    //  console.log(index);
    let allQr = qr;
    allQr[index]["IsActive"] = status;
    //  console.log(allQr);
    setQr(allQr);
  };

  const getActive = (imagedetails, status) => {
    //  console.log(imagedetails);

    setLoading(true);
    const config = {
      method: "post",
      url: `${serverUrl}/sessions/ActiveOrInactive`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        qrId: imagedetails._id,
        status: status,
      },
    };

    axios(config)
      .then((response) => {
        //  console.log(response);
        updateStatusBy_id(imagedetails._id, status);

        setActive(response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getQrImage = () => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${serverUrl}/sessions/get-session-by-id`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };
    //  console.log(config);

    axios(config)
      .then((response) => {
        //  console.log(response.data);
        setQr(response.data);
        setLoading(false);
      })
      .catch((err) => {
        toast("Couldn't load Qr Images. Please refresh.", darkToast);
      });
    setLoading(false);
  };

  const getSessions = () => {
    setLoading(true);

    const config = {
      method: "get",
      url: `${serverUrl}/sessions/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };

    axios(config).then((response) => {
      setSessions(response.data);
      //console.log(response.data);
      setSessions(response.data);
      localStorage.setItem("sessions", JSON.stringify(response.data));
      setLoading(false);
    });
  };

  function downloadBase64File(base64Data, fileName) {
    const linkSource = base64Data;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = `Qr_code_${fileName}`;
    downloadLink.click();
  }

  const timeConverter = function (timestamp) {
    const date = new Date(timestamp * 1000);
    const day = `${date.getDate()}`.padStart(2, 0);
    const month = `${date.getMonth() + 1}`.padStart(2, 0);
    const year = `${date.getFullYear()}`.padStart(2, 0);
    const minutes = `${date.getMinutes()}`.padStart(2, 0);
    const sec = `${date.getSeconds()}`.padStart(2, 0);
    const hr =
      date.getHours() > 12
        ? `${date.getHours() - 12}:${minutes}:${sec}PM`
        : `${date.getHours()}:${minutes}:${sec}AM`;
    // console.log(date);
    return `${day}/${month}/${year}   ${hr}`;
  };

  const Download = () => {
    var zip = new JSZip();
    var img = zip.folder("images");
    var count = 0;
    const session = sessions.find((el) => el._id === sessionId);
    var zipFilename = session.SessionName + ".zip";

    var donwloadAll = () =>
      qr.forEach(function (image, index) {
        var filename = "Qr Code - " + image.QRCode + ".png";

        // loading a file and add it in a zip file

        JSZipUtils.getBinaryContent(image.QrURL, async function (err, data) {
          if (err) {
            throw err; // or handle the error
          }
          img.file(filename, data, { binary: true });
          count++;
          if (count === qr.length) {
            var zipFile = await zip.generateAsync({ type: "blob" });
            saveAs(zipFile, zipFilename);
          }
        });
      });
    return (
      <button onClick={donwloadAll} className="downloadButton">
        Download all
      </button>
    );
  };

  return (
    <>
      <Modal
        isOpen={showMore}
        style={{
          content: {
            borderRadius: "1rem",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
          overlay: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        }}
      >
        <div className="show">
          <div
            style={{
              height: loading ? "70vh" : "",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <table>
              {sessions.map((session, index) => {
                return session._id === sessionId ? (
                  <div className="content-title" key={index}>
                    <div className="style-size">
                      <tr>
                        <th className="rewardname">Reward Name</th>
                        <th className="rewardname">Quantitiy</th>
                      </tr>

                      {session.rewardprofile[0].Rewards.map((reward, index) => (
                        <tr key={index}>
                          <td className="rewardname">{reward?.RewardName}</td>
                          <td>{reward?.Quantity}</td>
                        </tr>
                      ))}
                      <tr>
                        <td className="rewardname">Total</td>
                        <td>{total}</td>
                      </tr>
                    </div>
                  </div>
                ) : null;
              })}
            </table>
          </div>
        </div>

        {loading ? (
          <>
            <br />
            <ReactLoading
              type={"spinningBubbles"}
              color={"var(--primaryColor)"}
              width={"2rem"}
              height={"2rem"}
            />
          </>
        ) : (
          <div className="modalButtonsRow">
            <button className="cancelbutton" onClick={() => setshowMore(false)}>
              Close
            </button>
          </div>
        )}
      </Modal>
      <div>
        {sessions.map((session, index) => {
          return session._id === sessionId ? (
            <div className="content-title" key={index}>
              <div className="AllQR">
                <div className="gap">
                  <p>
                    Session Status :
                    <strong className={session.Status ? "Active" : "Inactive"}>
                      {session.Status ? "Active" : "Inactive"}
                    </strong>
                  </p>
                </div>
                <Typography>
                  Time Based :{" "}
                  <strong className={session.TimeBased ? "ON" : "OFF"}>
                    {session.TimeBased ? "ON" : "OFF"}
                  </strong>
                </Typography>
                <p>
                  Download all QR's :
                  {/* <Button
                    className="down"
                    variant="contained"
                    size="small"
                    style={{ marginLeft: 5, backgroundColor: "#fd6868",borderRadius: "10px", fontSize: "12px"}}
                    onClick={() => {
                      // downloadBase64File(image.QrURL, image.QRCode);
                      qr.forEach((image, index) => {
                        downloadBase64File(image.QrURL, image.QRCode);
                      });
                    }}
                  >
                    Download all
                  </Button> */}
                  <Download />
                </p>
              </div>

              <Typography>
                Session Name: <strong>{session.SessionName}</strong>
              </Typography>
              <Typography>
                No. of QR's: <strong>{session.RewardCount}</strong>
              </Typography>

              <Typography>
                RewardProfile :
                <strong>
                  <span
                    className="rewardprofilename"
                    onClick={() => {
                      setselectedReward(session);
                      setshowMore(true);
                    }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        marginLeft: 5,
                        backgroundColor: "#fd6868",
                        borderRadius: "10px",
                        fontSize: "12px",
                        width: "auto",
                      }}
                      className="downloadQrview"
                    >
                      {session.rewardprofile[0].rewardprofileName}
                    </Button>
                  </span>
                </strong>
              </Typography>
            </div>
          ) : null;
          // <p>Total Qr:{qrCount.RewardCount}</p>
        })}
      </div>
      {qr.map((image, index) => (
        <div
          className="qr-code"
          style={{ marginRight: 25, marginTop: 25 }}
          key={index}
        >
          <Card style={{ backgroundColor: "#f5f5f5" }}>
            {(image.StartTime && image.EndTime) === 0 ? (
              <CardContent>
                <Typography>Non Time Based</Typography>
              </CardContent>
            ) : (
              <CardContent>
                <Typography>
                  Start : {timeConverter(image.StartTime)}
                </Typography>
                <Typography>End : {timeConverter(image.EndTime)}</Typography>
              </CardContent>
            )}
            <CardMedia
              component="img"
              height="250"
              style={{
                marginTop: "6px",
                padding: "5px",
                marginBottom: "4px",
              }}
              image={image.QrURL}
              alt="qr"
            />
            <CardContent>
              <Typography
                variant="h5"
                style={{
                  marginTop: "10px",
                  fontSize: "18px",
                  marginLeft: "10px",
                }}
              >
                Qr No: <strong>{image.QRCode}</strong>
              </Typography>
              <CardActions>
                <Button
                  style={{
                    backgroundColor: "#fd6868",
                    borderRadius: "10px",
                    marginTop: "10px",
                    fontSize: "12px",
                  }}
                  size="small"
                  variant="contained"
                  onClick={() => {
                    downloadBase64File(image.QrURL, image.QRCode);
                  }}
                >
                  Download QR
                </Button>
                <Button
                  style={{
                    backgroundColor: "#fd6868",
                    borderRadius: "10px",
                    marginTop: "10px",
                    fontSize: "12px",
                  }}
                  size="small"
                  variant="contained"
                  onClick={() => {
                    history.push(`/sessions/QR_Reports/${QRCode[index]}/`);
                  }}
                >
                  View
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </div>
      ))}
    </>
  );
};

export default Qrpage;
