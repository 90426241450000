import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCRjis9J8XO6KQoaDX_DnjECnCadWOGHQQ",
  authDomain: "cgrum-qr-app.firebaseapp.com",
  projectId: "cgrum-qr-app",
  storageBucket: "cgrum-qr-app.appspot.com",
  messagingSenderId: "210569353077",
  appId: "1:210569353077:web:83b53c9019e11f44d8e53c",
};

firebase.initializeApp(firebaseConfig);
var storage = firebase.storage();

export default storage;
