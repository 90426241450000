import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Route } from "react-router-dom";
import { ToastContainer, toast, darkToast } from "../../Components/Toast";
import ReactLoading from "react-loading";
import { serverUrl } from "../../Utils/server";
import axios from "axios";
import FontAwesome from "react-fontawesome";
import Modal from "react-modal";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";

function Customers() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [deleteUser, setDeleteUser] = useState("");
  const [approveUser, setApproveUser] = useState("");
  //   const [searchUserEmail, setSearchUserEmail] = useState("");
  const [sentmails, setSendMails] = useState([]);

  useEffect(() => {
    getCustomers();
  }, []);

  const getCustomers = () => {
    setLoading(true);

    const config = {
      method: "get",
      url: `${serverUrl}/customer/GetCustomers`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then((response) => {
        setCustomers(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  //   const getUsersByType = (type) => {
  //     if (type === "all") return getUsers();
  //     setLoading(true);

  //     const config = {
  //       method: "post",
  //       url: `${serverUrl}/users/get-users-by-type`,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `JWT ${localStorage.getItem("token")}`,
  //       },
  //       data: {
  //         type: type,
  //       },
  //     };

  //     axios(config)
  //       .then((response) => {
  //         setUsers(response.data);
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //       });
  //   };

  //   const removeUser = () => {
  //     setLoading(true);

  //     const config = {
  //       method: "post",
  //       url: `${serverUrl}/users/remove`,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `JWT ${localStorage.getItem("token")}`,
  //       },
  //       data: {
  //         userId: deleteUser._id,
  //       },
  //     };

  //     axios(config)
  //       .then((response) => {
  //         toast("User deleted successfully!", darkToast);
  //         setLoading(false);
  //         setDeleteUser("");
  //         window.location.reload();
  //       })
  //       .catch((err) => {
  //         toast("Something went wrong", darkToast);
  //         setLoading(false);
  //       });
  //   };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function CustomToolbarGrid() {
    // const { data } = useDemoData({
    //   dataSet: "Employee",
    //   rowLength: 10,
    //   maxColumns: 6,
    // });

    const columns = [
      { field: "sno", headerName: "S NO", width: 70 },
      {
        headerName: "Name",
        field: "name",
        width: 120,
      },
      {
        headerName: "Email",
        field: "email",
        width: 270,
      },
      {
        headerName: "Mobile No",
        field: "phone",
        width: 130,
      },
      {
        headerName: "Joined Date",
        field: "created_at",
        width: 200,
        renderCell: (params) => {
          const dt = params.value.split("/");
          const [month, day, year] = dt;
          dt[0] = day;
          dt[1] = month;
          dt[2] = year;
          return (
            <>
              <p>{dt.join("-")}</p>
            </>
          );
        },
      },
      //   {
      //     headerName: "Edit",
      //     field: "edit",
      //     width: 100,
      //     renderCell: (params) => {
      //       const onClick = (e) => {
      //         e.stopPropagation(); // don't select this row after clicking
      //         history.push(`/users/edit/${params.id}`);

      //         console.log(params.id);
      //       };
      //       return (
      //         <>
      //           <button onClick={onClick}>Edit</button>
      //         </>
      //       );
      //     },
      //   },
      //   {
      //     headerName: "Delete",
      //     field: "delete",
      //     width: 100,
      //     renderCell: (params) => {
      //       const onClick = (e) => {
      //         e.stopPropagation(); // don't select this row after clicking
      //         let user = users.filter((item) => item.id === params.id);
      //         console.log(user[0]);
      //         // setDeleteUser(user[0]);

      //         console.log(params.id);
      //       };
      //       return (
      //         <>
      //           <button onClick={onClick}>Delete</button>
      //         </>
      //       );
      //     },
      //   },
    ];

    const ext = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    // const dateFormatter = function (date) {
    //   const dt = new Date(date);
    //   const year = dt.getFullYear();
    //   const month = `${dt.getMonth() + 1}`.padStart(2, 0);
    //   const day = `${dt.getDate()}`.padStart(2, 0);
    //   return `${day}/${month}/${year}`;
    // };
    // console.log(dateFormatter(1664610240289));

    // const dateFormatter2 = function (date) {
    //   const formattedDate = Intl.DateTimeFormat("en-uk", {
    //     day: "numeric",
    //     month: "numeric",
    //     year: "numeric",
    //     // hour: "numeric",
    //     // minute: "numeric",
    //     // second: "numeric",
    //   }).format(date);
    //   return formattedDate;
    // };

    let rows = customers;
    rows.forEach((element, i) => {
      rows[i]["id"] = rows[i]["_id"];
      rows[i]["sno"] = i + 1;
      // rows[i]["created_at"] = dateFormatter(element.created_at);
      rows[i]["created_at"] = new Date(element.created_at).toLocaleDateString();

      //   rows[i]["created_at"] = Intl.DateTimeFormat("en-uk", ext).format(
      //     element.created_at
      //   );
    });

    //console.log(rows);
    // console.log(dateFormatter2(customers[0].created_at));

    return (
      <div style={{ height: 560, width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          // {...data}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  return (
    <>
      <Route exact path="/customers">
        <Modal
          isOpen={deleteUser !== ""}
          style={{
            content: {
              borderRadius: "1rem",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
            overlay: {
              backgroundColor: "rgba(0,0,0,0.7)",
            },
          }}
        >
          <div className="modalContent">
            <p className="modalTitle">Delete User?</p>
            <p className="modalBody">
              Are you sure, want to delete{" "}
              <b>
                {`${deleteUser.name}`} ({deleteUser.email})
              </b>{" "}
              ?
            </p>
            {loading ? (
              <>
                <br />
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"var(--primaryColor)"}
                  width={"2rem"}
                  height={"2rem"}
                />
              </>
            ) : (
              <div className="modalButtonsRow">
                <button
                  className="cancelbutton"
                  onClick={() => setDeleteUser("")}
                >
                  Cancel
                </button>
                <button
                  className="deletebutton"
                  onClick={() => {
                    setLoading(true);
                    //   removeUser();
                    // toast("Can't delete User in Demo", darkToast);
                  }}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </Modal>

        <Modal
          isOpen={approveUser !== ""}
          style={{
            content: {
              borderRadius: "1rem",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
            overlay: {
              backgroundColor: "rgba(0,0,0,0.7)",
            },
          }}
        >
          <div className="modalContent">
            <p className="modalTitle">Approve User?</p>
            <p className="modalBody">
              Are you sure, want to Approve{" "}
              <b>
                {`${approveUser.name}`} ({approveUser.email})
              </b>{" "}
              ?
            </p>
            {loading ? (
              <>
                <br />
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"var(--primaryColor)"}
                  width={"2rem"}
                  height={"2rem"}
                />
              </>
            ) : (
              <div className="modalButtonsRow">
                <button
                  className="cancelbutton"
                  onClick={() => setApproveUser("")}
                >
                  Cancel
                </button>
                <button
                  className="deletebutton"
                  onClick={() => {
                    // setLoading(true);
                    // Sentmail();
                    // toast("Can't delete User in Demo", darkToast);
                  }}
                >
                  Approve
                </button>
              </div>
            )}
          </div>
        </Modal>
        <div className="content-body">
          <div className="content-title">
            <p>Customers</p>
            {/* <button
              className="adduserbutton"
              onClick={() => {
                history.push("/users/new");
              }}
            >
              + Add New User
            </button> */}
          </div>
          <div
            style={{
              height: loading ? "70vh" : "",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <ReactLoading
                type={"spinningBubbles"}
                color={"var(--primaryColor)"}
                width={"4rem"}
                height={"4rem"}
              />
            ) : (
              <CustomToolbarGrid />
            )}
          </div>
          <ToastContainer />
        </div>
      </Route>
      {/* <Route path="/users/new" component={AddUser} />
      <Route path="/users/edit/:userId" component={EditUser} /> */}
    </>
  );
}

export default Customers;
