// import storage from "../../Firebase/config";
import React, { useState, useEffect } from "react";
import { darkToast, toast, ToastContainer } from "../../Components/Toast";
import ReactLoading from "react-loading";
import { useHistory, useParams } from "react-router";
import axios from "axios";
import { serverUrl } from "../../Utils/server";

const EditEvents = () => {
  const { eventId } = useParams();
  const history = useHistory();
  const [eventsName, setEventsName] = useState("");
  // const [eventsImage, setEventsImage] = useState("");

  const [loading, setLoading] = useState(false);
  // const [uploadingImage, setUploadingImage] = useState(false);

  useEffect(() => {
    getEventsDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEventsDetails = () => {
    setLoading(true);
    const config = {
      method: "post",
      url: `${serverUrl}/events/get-event-by-id`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        eventId: eventId,
      },
    };

    axios(config)
      .then((response) => {
        setEventsName(response.data.EventName);
        // setEventsImage(response.data.image);
        setLoading(false);
      })
      .catch((err) => {
        toast("Failed to fetch Events info", darkToast);
        setLoading(false);
      });
  };

  let existingEvents = [];
  let filteredEvents = [];
  const getEvents = () => {
    const config = {
      method: "get",
      url: `${serverUrl}/events/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };
    axios(config).then((response) => {
      existingEvents = response.data;
      // console.log(existingEvents);
      filteredEvents = existingEvents.map(({ EventName }) => EventName.toLowerCase());
      // console.log(filteredEvents);
      // getDataRewards(filteredRewards);
      setLoading(false);
    });
  };
  getEvents();

  const updateEvents = () => {
    if (eventsName === "") {
      return toast("Events name is required", darkToast);
    }else if (filteredEvents.includes(eventsName.trim().toLowerCase())) {
      return toast("The event already exists", darkToast);
    }
    // if (eventsImage === "") return toast("Upload Image", darkToast);

    setLoading(true);
    const config = {
      method: "post",
      url: `${serverUrl}/events/update`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        eventId: eventId,
        query: {
          EventName: eventsName,
          // image: eventsImage,
        },
      },
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          toast("Events updated!", darkToast);

          setTimeout(() => {
            history.push("/events");
            window.location.reload();
          }, 1000);
        }
        setLoading(false);
      })
      .catch(() => {
        toast("Something went wrong!", darkToast);
        setLoading(false);
      });
  };

  // const uploadImage = (image) => {
  //   setUploadingImage(true);

  //   const fileName = `${
  //     image.name + "" + eventsName + "" + Date.now().toString()
  //   }`;

  //   const upload = storage.ref(`images/${fileName}`).put(image);
  //   upload.on(
  //     "state_changed",
  //     (snapshot) => () => {},
  //     (error) => {
  //     },
  //     () => {
  //       storage
  //         .ref("images")
  //         .child(`${fileName}`)
  //         .getDownloadURL()
  //         .then((url) => {
  //           setEventsImage(url);
  //           setUploadingImage(false);
  //         });
  //     }
  //   );
  // };

  return (
    <div className="content-body">
      <div className="content-title">
        <p>Edit Events</p>
      </div>
      <div className="form">
        <p>Events Name</p>
        <input
          type="text"
          placeholder="Events Name"
          maxLength={25}
          value={eventsName}
          onChange={(e) => {
            setEventsName(e.target.value);
          }}
        />
        {/* <p>Events Image</p>
        {eventsImage !== "" ? (
          <img
            style={{ width: "20%", marginTop: "1rem" }}
            src={eventsImage}
            alt="EventsImage"
          />
        ) : (
          ""
        )}
        {uploadingImage ? (
          <div style={{ margin: "1rem" }}>
            <ReactLoading
              type={"spinningBubbles"}
              color={"var(--primaryColor)"}
              width={"2rem"}
              height={"2rem"}
            />
          </div>
        ) : (
          <input
            type="file"
            onChange={(e) => {
              uploadImage(e.target.files[0]);
            }}
          />
        )} */}
        <button
          onClick={() => {
            updateEvents();
            // toast("Can't edit Events in Demo", darkToast);
          }}
        >
          {loading ? (
            <ReactLoading
              type={"spinningBubbles"}
              color={"white"}
              width={"1.2rem"}
              height={"1.2rem"}
            />
          ) : (
            "Save"
          )}
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditEvents;