//import storage from "../../Firebase/config";
import React, { useState } from "react";
import { darkToast, toast, ToastContainer } from "../../Components/Toast";
import ReactLoading from "react-loading";
import { useHistory } from "react-router";
import axios from "axios";
import { serverUrl } from "../../Utils/server";

export const AddEvents = () => {
  const history = useHistory();
  const [eventsName, setEventsName] = useState("");
  // const [imageUrl, setImageUrl] = useState("");

  const [loading, setLoading] = useState(false);
  //const [uploadingImage, setUploadingImage] = useState(false);

  // const uploadImage = (image) => {
  //   setUploadingImage(true);

  // const fileName = `${
  //   image.name + "_" + eventsName + "_" + Date.now().toString()
  // }`;

  //   const upload = storage.ref(`images/${fileName}`).put(image);
  //   upload.on(
  //     "state_changed",
  //     (snapshot) => () => {},
  //     (error) => {},
  //     () => {
  //       storage
  //         .ref("images")
  //         .child(`${fileName}`)
  //         .getDownloadURL()
  //         .then((url) => {
  //           setImageUrl(url);
  //           setUploadingImage(false);
  //         });
  //     }
  //   );
  // };

  let existingEvents = [];
  let filteredEvents = [];
  const getEvents = () => {
    const config = {
      method: "get",
      url: `${serverUrl}/events/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };
    axios(config).then((response) => {
      existingEvents = response.data;
      // console.log(existingEvents);
      filteredEvents = existingEvents.map(({ EventName }) => EventName.toLowerCase());
      // console.log(filteredEvents);
      // getDataRewards(filteredRewards);
      setLoading(false);
    });
  };
  getEvents();
  
  const addEvents = () => {
    if (eventsName === "") {
      return toast("Event name is required", darkToast);
    }else if (filteredEvents.includes(eventsName.toLowerCase().trim())) {
      return toast("The event already exists", darkToast);
    }
    // if (imageUrl === "") return toast("Upload Image", darkToast);

    setLoading(true);
    const config = {
      method: "post",
      url: `${serverUrl}/events/add`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        EventName: eventsName,
        // image: imageUrl,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          toast("Events added!", darkToast);

          setTimeout(() => {
            history.push("/events");
            window.location.reload();
          }, 1000);
          setLoading(false);
        }
      })
      .catch(() => {
        toast("Something went wrong!", darkToast);
        setLoading(false);
      });
  };

  return (
    <div className="content-body">
      <div className="content-title">
        <p>Add New Events</p>
      </div>
      <div className="form">
        <p>Events Name</p>
        <input
          type="text"
          maxLength={25}
          placeholder="Events Name"
          onChange={(e) => {
            setEventsName(e.target.value);
          }}
        />
        {/* <p>Events Image</p>
        {uploadingImage ? (
          <div style={{ margin: "1rem" }}>
            <ReactLoading
              type={"spinningBubbles"}
              color={"var(--primaryColor)"}
              width={"2rem"}
              height={"2rem"}
            />
          </div>
        ) : imageUrl !== "" ? (
          <img
            style={{
              width: "20%",
              margin: "1rem",
              objectFit: "contain",
            }}
            src={imageUrl}
            alt=""
          />
        ) : null}
        <input
          type="file"
          onChange={(e) => {



            uploadImage(e.target.files[0]);
          }}
        /> */}
        <button
          onClick={() => {
            addEvents();
            //  toast("Can't add Events in Demo", darkToast);
          }}
        >
          {loading ? (
            <ReactLoading
              type={"spinningBubbles"}
              color={"white"}
              width={"1.2rem"}
              height={"1.2rem"}
            />
          ) : (
            "Add Events"
          )}
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};
