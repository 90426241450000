import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { serverUrl } from "../../Utils/server";
import axios from "axios";
import FontAwesome from "react-fontawesome";
import { toast, ToastContainer, darkToast } from "../../Components/Toast";
import Sessions from "./Sessions";
import { useParams } from "react-router";
import Modal from "react-modal/lib/components/Modal";
import { Route, useHistory } from "react-router";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";

function ViewReports() {
  const { sessionId } = useParams();
  const history = useHistory();

  // const { qrCodeID } = useParams();

  const [loading, setLoading] = useState(false);
  const [qrCodeData, setQRCodeData] = useState("");

  const [rewardData, setRewardData] = useState("");
  const [sessions, setSessions] = useState("");

  // console.log(QRCode);
  //  console.log(sessionId);

  useEffect(() => {
    // getRewardData();
    getQrData();
  }, []);

  // const getRewardData = () => {
  //   setLoading(true);

  //   const config = {
  //     method: "get",
  //     url: `${serverUrl}/reports/AvialbleRewards/${sessionId}`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `JWT ${localStorage.getItem("token")}`,
  //     },
  //   };

  //   axios(config).then((response) => {
  //     console.log(response);
  //     setRewardData(response.data);
  //     console.log(response.data);
  //     setLoading(false);
  //   });
  // };
  const getQrData = () => {
    setLoading(true);

    const config = {
      method: "post",
      url: `${serverUrl}/reports/QrDetails/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        QRCode: sessionId,
      },
    };

    axios(config).then((response) => {
      //  console.log(response);
      setQRCodeData(response.data);
      //console.log(response.data);
      setLoading(false);
    });
  };

  //   const removeReports = () => {
  //     setLoading(true);
  //     const config = {
  //       method: "post",
  //       url: `${serverUrl}/reports/remove`,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `JWT ${localStorage.getItem("token")}`,
  //       },
  //       data: {
  //         reportsId: deleteViewReports._id,
  //       },
  //     };

  //     axios(config)
  //       .then((response) => {
  //         toast("Reports deleted successfully!", darkToast);
  //       })
  //       .catch((err) => {
  //         toast("Something went wrong", darkToast);
  //       });

  //     setDeleteViewReports("");
  //     getReports();
  //     setLoading(false);
  //   };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function CustomToolbarGrid() {
    const { data } = useDemoData({
      dataSet: "Employee",
      rowLength: 10,
      maxColumns: 6,
    });

    const columns = [
      { field: "sno", headerName: "S NO", width: 110 },
      {
        headerName: "Reward Name",
        field: "RewardName",
        width: 180,
      },
      {
        headerName: "Total Rewards",
        field: "TotalRewards",
        width: 180,
      },
      {
        headerName: "Scanned Rewards",
        field: "ScannedRewards",
        width: 180,
      },
      {
        headerName: "Rewards left",
        field: "Quantity",
        width: 180,
      },
      {
        headerName: "Report",
        field: "report",
        width: 100,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation(); // don't select this row after clicking
            // let session = sessions.filter((item) => item.id === params.id);
            // history.push(`/sessions/QR_Reports/${params.id}`);
            // setreportSession(session[0]);
            //  console.log("Hii");
          };
          return (
            <>
              <button
                onClick={() => {
                  history.push(`/sessions/Reports/${sessionId}/${params.id}`);
                  //  console.log(params.id);
                  //  console.log(sessionId);
                }}
              >
                View
              </button>
            </>
          );
        },
      },
    ];

    const totalRewards = qrCodeData[0].QrImage[0].Rewards.map(
      (obj) => +obj.TotalRewards
    );

    const leftOutRewards = qrCodeData[0].QrImage[0].Rewards.map(
      (obj) => +obj.Quantity
    );

    const scannedRewards = [];
    for (let i = 0; i < totalRewards.length; i++) {
      scannedRewards.push(totalRewards[i] - leftOutRewards[i]);
    }

    let rows = qrCodeData[0].QrImage[0].Rewards;
    //rows.rewardData[0].Remaining_Rewards = rewardsLeft;
    rows.forEach((el, i) => {
      el.ScannedRewards = scannedRewards[i];
    });

    rows.forEach((element, i) => {
      rows[i]["id"] = rows[i]["RewardId"];
      rows[i]["sno"] = i + 1;
      //rows[i]["report"] = rows[i]["RewardId"];

      //   rows[i]["Quantity"] = rows[i]["Quantity"][0]?.Quantity;
    });

    // console.log(totalRewards);
    // console.log(rows);
    // console.log(leftOutRewards);
    // console.log(scannedRewards);

    return (
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          // {...data}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  return (
    <>
      {/* <Modal
        isOpen={deleteViewReports !== ""}
        style={{
          content: {
            borderRadius: "1rem",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
          overlay: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        }}
      >
        <div className="modalContent">
          <p className="modalTitle">Delete Reports?</p>
          <p className="modalBody">
            Are you sure, want to delete{" "}
            <b>{`${deleteViewReports.username}`}</b> ?
          </p>
          {loading ? (
            <>
              <br />
              <ReactLoading
                type={"spinningBubbles"}
                color={"var(--primaryColor)"}
                width={"2rem"}
                height={"2rem"}
              />
            </>
          ) : (
            <div className="modalButtonsRow">
              <button
                className="cancelbutton"
                onClick={() => setDeleteViewReports("")}
              >
                Cancel
              </button>
              <button
                className="deletebutton"
                onClick={() => {
                  setLoading(true);
                  removeReports();
                  // toast("Can't delete Reports in Demo", darkToast);
                }}
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </Modal> */}

      <div className="content-body">
        <div className="content-title">
          <p>Reward Data</p>
        </div>
        <div
          style={{
            height: loading ? "70vh" : "",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <ReactLoading
              type={"spinningBubbles"}
              color={"var(--primaryColor)"}
              width={"4rem"}
              height={"4rem"}
            />
          ) : qrCodeData.length === 0 ? (
            <p>No Reward Data</p>
          ) : (
            <CustomToolbarGrid />
          )}
        </div>

        <ToastContainer />
      </div>
    </>
  );
}

export default ViewReports;
