import React, { useState } from "react";
import "./Signup.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import ReactLoading from "react-loading";
import { logo, serverUrl } from "../../Utils/server";
import { toast, ToastContainer, darkToast } from "../../Components/Toast";
import { useHistory } from "react-router-dom";

function SignUp() {
  const [name, setFullName] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const signUp = () => {
    if (name === "") {
      return toast("name is required", darkToast);
    } else if (phone === "") {
      return toast("Phone is required", darkToast);
    } else if (phone.length < 10 || phone.length > 10) {
      return toast("enter valid phone number", darkToast);
    } else if (email === "") {
      return toast("email is required", darkToast);
    } else if (password.length < 6 || password.length > 20) {
      return toast(
        "Password should have minimum 6 and maximum 20 characters",
        darkToast
      );
    } else if (address === "") {
      return toast("Address is required", darkToast);
    }

    setLoading(true);

    const config = {
      method: "post",
      url: `${serverUrl}/register`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        name: name,
        phone: phone,
        email: email,
        password: password,
        address: address,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          toast("Registered!", darkToast);

          setTimeout(() => {
            history.push("/login");
            window.location.reload();
          }, 1000);
          setLoading(false);
        }
      })
      .catch(() => {
        toast("Something went wrong!", darkToast);
        setLoading(false);
      });
  };

  return (
    <div className="login-container">
      <div className="form-container">
        <div className="logo-container">
          <img src={logo} alt="Logo" />
          <p>Cgrum App</p>
        </div>
        <p className="title">Sign-up</p>
        <br />
        <h1>create your account.</h1>
        <br />
        <div className="input-containers">
          <input
            placeholder="Full Name"
            type="fullname"
            value={name}
            onChange={(e) => setFullName(e.target.value)}
          />
          <input
            placeholder="Phone Number"
            type="phonenumber"
            value={phone}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <input
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            placeholder="Address"
            type="area"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <button
          onClick={() => {
            signUp();
            // toast("Can't add User in Demo", darkToast);
          }}
        >
          {loading ? (
            <ReactLoading
              type={"spinningBubbles"}
              color={"white"}
              width={"1.2rem"}
              height={"1.2rem"}
            />
          ) : (
            "submit"
          )}
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default SignUp;
