import React, { useState, useEffect } from "react";
import { darkToast, toast, ToastContainer } from "../../Components/Toast";
import ReactLoading from "react-loading";
import { useHistory, useParams } from "react-router";
import axios from "axios";
import { serverUrl } from "../../Utils/server";

const EditReward = () => {
  const { rewardId } = useParams();
  const history = useHistory();
  const [RewardType, setRewardName] = useState("");
  // const [rewardImage, setRewardImage] = useState("");

  const [loading, setLoading] = useState(false);
  // const [uploadingImage, setUploadingImage] = useState(false);

  useEffect(() => {
    getRewardDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRewardDetails = () => {
    setLoading(true);
    const config = {
      method: "post",
      url: `${serverUrl}/rewards/get-reward-by-id`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        rewardId: rewardId,
      },
    };

    axios(config)
      .then((response) => {
        setRewardName(response.data.RewardType);
        // setEventsImage(response.data.image);
        setLoading(false);
      })
      .catch((err) => {
        toast("Failed to fetch Reward info", darkToast);
        setLoading(false);
      });
  };

  let existingRewards = [];
  let filteredRewards = [];
  const getRewards = () => {
    const config = {
      method: "get",
      url: `${serverUrl}/rewards/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };
    axios(config).then((response) => {
      existingRewards = response.data;
      // console.log(existingRewards);
      filteredRewards = existingRewards.map(({ RewardType }) =>
        RewardType.toLowerCase()
      );
      // console.log(filteredRewards);
      // getDataRewards(filteredRewards);
      setLoading(false);
    });
  };
  getRewards();

  const updateReward = () => {
    if (RewardType === "") {
      return toast("Reward name is required", darkToast);
    } else if (filteredRewards.includes(RewardType.trim().toLowerCase())) {
      return toast("The reward already exists", darkToast);
    }
    // if (rewardImage === "") return toast("Upload Image", darkToast);

    setLoading(true);
    const config = {
      method: "post",
      url: `${serverUrl}/rewards/update`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        rewardId: rewardId,
        query: {
          RewardType: RewardType,
          // image: rewardImage,
        },
      },
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          toast("Reward updated!", darkToast);

          setTimeout(() => {
            history.push("/reward");
            window.location.reload();
          }, 1000);
        }
        setLoading(false);
      })
      .catch(() => {
        toast("Something went wrong!", darkToast);
        setLoading(false);
      });
  };

  // const uploadImage = (image) => {
  //   setUploadingImage(true);

  //   const fileName = `${
  //     image.name + "_" + eventsName + "_" + Date.now().toString()
  //   }`;

  //   const upload = storage.ref(`images/${fileName}`).put(image);
  //   upload.on(
  //     "state_changed",
  //     (snapshot) => () => {},
  //     (error) => {
  //     },
  //     () => {
  //       storage
  //         .ref("images")
  //         .child(`${fileName}`)
  //         .getDownloadURL()
  //         .then((url) => {
  //           setEventsImage(url);
  //           setUploadingImage(false);
  //         });
  //     }
  //   );
  // };

  return (
    <div className="content-body">
      <div className="content-title">
        <p>Edit Reward</p>
      </div>
      <div className="form">
        <p>Reward Name</p>
        <input
          type="text"
          maxLength={25}
          placeholder="Reward Name"
          value={RewardType}
          onChange={(e) => {
            setRewardName(e.target.value);
          }}
        />
        {/* <p>Events Image</p>
        {eventsImage !== "" ? (
          <img
            style={{ width: "20%", marginTop: "1rem" }}
            src={eventsImage}
            alt="EventsImage"
          />
        ) : (
          ""
        )}
        {uploadingImage ? (
          <div style={{ margin: "1rem" }}>
            <ReactLoading
              type={"spinningBubbles"}
              color={"var(--primaryColor)"}
              width={"2rem"}
              height={"2rem"}
            />
          </div>
        ) : (
          <input
            type="file"
            onChange={(e) => {
              uploadImage(e.target.files[0]);
            }}
          />
        )} */}
        <button
          onClick={() => {
            updateReward();
            // toast("Can't edit Reward in Demo", darkToast);
          }}
        >
          {loading ? (
            <ReactLoading
              type={"spinningBubbles"}
              color={"white"}
              width={"1.2rem"}
              height={"1.2rem"}
            />
          ) : (
            "Save"
          )}
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditReward;
